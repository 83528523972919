<template>
  <div class="client-products-details client-page">
    <div class="flex flex-center content-title">
      <span class="cursor-pointer" @click="goBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 flex flex-center font-size-20">
        商品详情
      </div>
    </div>
    <div v-loading="loading" class="flex content-details">
      <div v-if="info.images && info.images.length" class="content-details-left">
        <el-carousel height="400px">
          <el-carousel-item v-for="item in info.images" :key="item">
            <el-image class="width-full height-full" :src="item" fit="cover" :preview-src-list="info.images"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else class="content-details-left">
        <el-image class="width-full" :src="imgDefault" fit="cover"></el-image>
      </div>
      <div class="content-details-right">
        <div class="flex flex-row-center">
          <el-tag v-if="false" type="warning">
            热销
          </el-tag>
          <div class="font-size-24">【{{ info.name }}】{{ item.code }}</div>
          <div class="font-size-24" v-show="false">{{ goodsNameText() }}</div>
        </div>

        <div class="flex flex-row-center m-t-15">
          <el-tag v-if="info.promotion" type="danger" effect="dark" size="small" class="m-r-10">
            促销
          </el-tag>
          <el-tag v-if="info.limitString" type="warning" effect="dark" size="small" class="m-r-10">
            限购
          </el-tag>
          <!-- 优惠券 -->
          <GoodsDetailsCoupon class="flex1" :list="info.coupons || []" @refresh="getGoodsDetail" />
        </div>

        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">价格：</span>
          <span class="text-price font-weight-bolder font-size-24"> ￥{{ renderPriceFn(info.price) }} </span>
          <span class="line-price m-l-20 m-t-5" v-if="info.linePrice">￥{{ info.linePrice }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">品牌：</span>
          <span>{{ info.brand }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">产地：</span>
          <span>{{ info.productionPlace }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">产品分类：</span>
          <span>{{ info.category }}</span>
        </div>
        <div v-if="false" class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">卖点：</span>
          <span>
            卖点内容内卖点内容内卖点内容内卖点内容内卖点内容
          </span>
        </div>

        <div v-if="false && !(type === 'order')" class="flex m-t-15 font-size-18">
          <span class="min-w-120" style="line-height: 35px;">性质：</span>
          <div class="flex1 flex overflow-hidden">
            <div ref="propertyTagBoxRef" class="flex1 overflow-hidden" :class="showMoreProperty ? '' : 'h-40'">
              <el-button
                ref="propertyTagItemRef"
                class="tag-btn"
                v-for="(item, index) in propertyList"
                :key="index"
                :type="item.property === goodsOptions.property ? 'primary' : ''"
                >{{ item.property }}</el-button
              >
            </div>
          </div>
          <el-button
            v-if="isShowMorePropertyBtn"
            @click="showMoreProperty = !showMoreProperty"
            type="warning"
            plain
            class="h-35 p-lr-15 p-tb-5"
          >
            {{ showMoreProperty ? "点击收起" : "展开更多" }}
          </el-button>
        </div>

        <template v-if="!(type === 'order')">
          <div v-for="(row, i) in optionList" :key="i">
            <div class="flex m-t-15 font-size-18" v-if="row.field == 'warehouse' && row.active && warehouseList.length">
              <span class="min-w-120" style="line-height: 35px;">{{ renderModelName(row) }}：</span>

              <div class="flex1 flex overflow-hidden">
                <div
                  ref="stockTagBoxRef"
                  class="flex1"
                  :class="showMoreStock ? 'max-h-130 overflow-auto' : 'h-35 overflow-hidden'"
                >
                  <el-button
                    ref="stockTagItemRef"
                    class="tag-btn"
                    v-for="(item, index) in warehouseList"
                    :key="index"
                    :type="item.warehouse === goodsOptions.warehouse ? 'primary' : ''"
                    :disabled="disabledWarehouse(item)"
                    @click="selectWarehouse(item)"
                  >
                    {{ renderWarehouse(item, row) }}
                  </el-button>
                </div>
              </div>
              <el-button
                v-if="isShowMoreStockBtn"
                @click="showMoreStock = !showMoreStock"
                type="warning"
                plain
                class="h-35 p-lr-15 p-tb-5 m-l-5"
              >
                {{ showMoreStock ? "点击收起" : "展开更多" }}
              </el-button>
            </div>
            <div
              class="flex m-t-15 font-size-18"
              v-if="row.field == 'productBatch' && row.active && productBatchList.length"
            >
              <span class="min-w-120" style="line-height: 35px;">{{ renderModelName(row) }}：</span>

              <div class="flex1 flex overflow-hidden">
                <div
                  ref="productBatchTagBoxRef"
                  class="flex1"
                  :class="showMoreProductBatch ? 'max-h-130 overflow-auto' : 'h-35 overflow-hidden'"
                >
                  <el-button
                    ref="productBatchTagItemRef"
                    class="tag-btn"
                    v-for="(item, index) in productBatchList"
                    :key="index"
                    :type="item.productBatch === goodsOptions.productBatch ? 'primary' : ''"
                    :disabled="disabledProductBatch(item)"
                    @click="selectProductBatch(item)"
                  >
                    {{ renderProductBatch(item, row) }}
                  </el-button>
                </div>
              </div>
              <el-button
                v-if="isShowMoreProductBatchBtn"
                @click="showMoreProductBatch = !showMoreProductBatch"
                type="warning"
                plain
                class="h-35 p-lr-15 p-tb-5 m-l-5"
              >
                {{ showMoreProductBatch ? "点击收起" : "展开更多" }}
              </el-button>
            </div>
          </div>
        </template>

        <div class="flex flex-row-center m-t-15 font-size-18" v-if="!(type === 'order')">
          <span class="min-w-120">库存数量：</span>
          <span v-if="isShowWarehouse && info.warehouse">{{ info.warehouse }}：</span>
          <span>{{ renderQty(info) }}</span>
          <span v-if="isShowProductBatch && info.productBatch">（生产批号：{{ info.productBatch }}）</span>
        </div>
        <div class="flex m-t-15" v-if="!(type === 'order')">
          <span class="min-w-120 font-size-18">数量：</span>
          <div class="flex1 ">
            <el-input-number
              v-model="orderQuantity"
              :min="info.minQty"
              :max="9999999"
              :step="Number(info.uQty)"
              size="small"
            ></el-input-number>
            <span class="m-l-10 font-size-20">{{ info.unit }}</span>
            <span v-if="Number(info.cartQty) > 0 || Number(info.minBuyQty) > 1" class="m-l-10 font-size-20">
              <span>（ </span>
              <span v-if="Number(info.cartQty) > 0">
                <span>已添加：</span>
                <span class="text-red">{{ info.cartQty }}</span>
              </span>
              <span v-if="Number(info.minBuyQty) > 1">
                <span class="m-l-5">起订量：</span>
                <span class="text-red">{{ info.minBuyQty }}</span>
              </span>
              <span>）</span>
            </span>
            <div class="text-warning m-t-15 font-size-18">
              <span v-if="info.promotion" class="m-r-10">{{ info.promotion }}</span>
              <span v-if="info.limitString">{{ info.limitString }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-row-center m-t-15" v-if="!(type === 'order')">
          <el-button @click="addShoppingCart" class="font-size-20 p-a-15" type="warning">
            加入购物车(Alt+F1)
          </el-button>
          <el-button @click="createOrder" class="font-size-20 p-a-15" type="primary">
            立即下单(F8)
          </el-button>
        </div>
      </div>
    </div>
    <div class="content-info">
      <el-tabs v-model="activeName">
        <el-tab-pane label="商品介绍" name="one">
          <el-descriptions ref="goodsdesc" class="margin-top" :column="2" border>
            <template v-for="(item, index) in goodsIntroduceList">
              <el-descriptions-item :key="index" :label="item.rename || item.name" v-if="index <= rowCount * 2 - 1">
                {{ item.value || "— —" }}
              </el-descriptions-item>
              <el-descriptions-item
                :key="index"
                :label="item.rename || item.name"
                v-if="index > rowCount * 2 - 1 && showMoreGoodsInfo"
              >
                {{ item.value || "— —" }}
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <div class="show-btns" v-if="showMoreBtn">
            <el-button size="small" type="text" @click="showMoreGoodsInfoFn">
              {{ showMoreGoodsInfo ? "点击收起" : "展开更多" }}
              <i class="el-icon-caret-top" v-if="showMoreGoodsInfo"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </el-button>
          </div>
          <div class="m-t-20 rich-text-content">
            <!-- 富文本内容 -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="适用车型" name="two">
          <div class="flex flex-row-center">
            <span class="min-w-110">车型：</span>
            <span>{{ info.vehModel }}</span>
          </div>
          <div class="flex flex-row-center m-t-5">
            <span class="min-w-110">适用车型：</span>
            <span>{{ info.avaVehModel }}</span>
          </div>
          <div class="flex flex-row-center m-t-5">
            <span class="min-w-110">标准适用车型：</span>
          </div>
          <div class="m-t-10">
            <HtTable
              :data="tableData"
              :columns="columns"
              :table-name="tableName"
              :key-scope="tableName"
              :auto-height="true"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <PlaceOrderType ref="placeOrderType" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsDetail, getOrderDetailsById } from "@/api/goods";
import { addShoppingCart } from "@/api/shoppingCart";
import PlaceOrderType from "@/components/PlaceOrderType";
import { getGoodsNameText } from "@/utils/getGoodsNameText";
import GoodsDetailsCoupon from "@/components/Coupon/GoodsDetailsCoupon";

export default {
  name: "ClientProductsDetails",
  components: {
    PlaceOrderType,
    GoodsDetailsCoupon
  },
  data() {
    return {
      keyName: "alt+f1,f8",
      keyScope: "ProductsDetails",
      imgDefault: require("@/assets/img/no-goods-img.png"),
      desc: "浏览商品-商品详情",
      loading: false,
      dialogVisible: false,
      billingType: "one",
      buyer: "",
      buyerOptions: [{ id: 1, name: "买家1" }],
      orderQuantity: 1,
      activeName: "one",
      tableName: "clientProductsDetails",
      tableData: [],
      columns: [{ label: "车型名称", prop: "name" }],
      item: {},
      info: {},
      goodsIntroduceList: [], // 商品介绍
      rowCount: 10,
      type: null,
      showMoreGoodsInfo: false,
      showMoreBtn: false,

      // 商品规格列表
      goodsSpecificationList: [
        // {
        //   warehouse: "A仓", // 仓库
        //   property: "", // 性质
        //   productBatch: "202401", // 生产批号
        //   actualPrice: "100", // 末端展示价格
        //   showPrice: "100", // 动态展示价格
        //   actualQty: "4", // 末端展示库存
        //   showQty: "2", // 动态展示库存
        //   show: "" // 展示内容
        // }
      ],
      // 商品选项列表
      optionList: [
        // {
        //   field: "warehouse", // 字段
        //   rename: "仓库", // 重命名
        //   active: true // 是否启用
        // },
        // {
        //   field: "productBatch", // 字段
        //   rename: "生产批号", // 重命名
        //   active: true // 是否启用
        // }
      ],
      goodsOptions: {
        warehouse: "",
        property: "",
        productBatch: ""
      },
      warehouseList: [],
      propertyList: [],
      productBatchList: [],

      isShowMorePropertyBtn: false,
      showMoreProperty: false,
      isShowMoreStockBtn: false,
      showMoreStock: false,
      isShowMoreProductBatchBtn: false,
      showMoreProductBatch: false,

      buyModelOption: {
        property: "性质",
        warehouse: "仓库",
        productBatch: "生产批号"
      }
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook", "companyInfo"]),
    // 商家端--基础设置-库存维度展示是否设置了生产批号
    isShowProductBatch() {
      return this.companyInfo.setting.stockShowModeProductBatch;
    },
    // 商家端--基础设置-库存维度展示是否设置了仓库
    isShowWarehouse() {
      return this.companyInfo.setting.stockShowModeWarehouse;
    },
    // 多规格
    isMoreModel() {
      return !!this.goodsSpecificationList.length;
    },
    selectModel() {
      let obj = null;
      const showWarehouse =
        this.optionList.find(item => item.field === "warehouse" && item.active) && this.warehouseList.length;
      const showProductBatch =
        this.optionList.find(item => item.field === "productBatch" && item.active) && this.productBatchList.length;
      // 显示仓库 + 生产批号
      if (showWarehouse && showProductBatch) {
        if (this.goodsOptions.warehouse && this.goodsOptions.productBatch) {
          obj = this.goodsSpecificationList.find(
            item =>
              item.warehouse === this.goodsOptions.warehouse && item.productBatch === this.goodsOptions.productBatch
          );
        }
      }
      // 显示仓库
      if (showWarehouse && !showProductBatch) {
        if (this.goodsOptions.warehouse) {
          obj = this.goodsSpecificationList.find(item => item.warehouse === this.goodsOptions.warehouse);
        }
      }
      // 显示生产批号
      if (!showWarehouse && showProductBatch) {
        if (this.goodsOptions.productBatch) {
          obj = this.goodsSpecificationList.find(item => item.productBatch === this.goodsOptions.productBatch);
        }
      }
      // console.log("selectModel", obj);
      return obj;
    }
  },
  watch: {
    goodsSpecificationList: {
      handler(val) {
        const list1 = val.filter(item => item.warehouse);
        this.warehouseList = window.tool.cutArray(list1, "warehouse");

        const list2 = val.filter(item => item.productBatch);
        this.productBatchList = window.tool.cutArray(list2, "productBatch");

        // 过滤掉不需要展示的项
        const optionArr = this.optionList.filter(item => {
          return (
            (item.field === "warehouse" && item.active && this.warehouseList.length) ||
            (item.field === "productBatch" && item.active && this.productBatchList.length)
          );
        });
        this.optionList = optionArr.map((item, i) => {
          if (i === optionArr.length - 1) {
            return {
              ...item,
              isLast: true
            };
          } else {
            return {
              ...item,
              isLast: false
            };
          }
        });
        // 多规格 仓库只有一条数据 生产批号为空 库存默认选中
        // if (this.warehouseList.length == 1 && !this.productBatchList.length) {
        //   this.goodsOptions.warehouse = this.warehouseList[0].warehouse;
        // }
        // // 多规格 仓库为空 生产批号只有一条数据 生产批号默认选中
        // if (!this.warehouseList.length && this.productBatchList.length == 1) {
        //   this.goodsOptions.productBatch = this.productBatchList[0].productBatch;
        // }
        // 多规格 只有一条数据 默认选中第一条
        if (val.length == 1) {
          this.goodsOptions.warehouse = val[0].warehouse || "";
          this.goodsOptions.productBatch = val[0].productBatch || "";
        }
      },
      deep: true
    },
    optionList: {
      handler(val) {
        if (val.find(item => item.field === "property" && item.active)) {
          this.setPropertyTagMore();
        }
        if (val.find(item => item.field === "warehouse" && item.active)) {
          this.setStockTagMore();
        }
        if (val.find(item => item.field === "productBatch" && item.active)) {
          this.setProductBatchTagMore();
        }
      },
      deep: true
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query && query.data) {
      this.item = JSON.parse(decodeURIComponent(query.data));
      this.getGoodsDetail();
    } else {
      // 从订单跳过来的数据 type:order
      this.type = this.$route.query.type;
      this.getOrderDetailsById();
    }

    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    goodsNameText() {
      return getGoodsNameText(this.info);
    },
    renderWarehouse(item, row) {
      if (row.isLast) {
        if (item.actualPrice || item.actualQty) {
          return (
            item.warehouse +
            `（${item.actualPrice ? "￥" + item.actualPrice + " " : ""}${item.actualQty}${
              isNaN(Number(item.actualQty)) ? "" : this.info.unit
            }）`
          );
        } else {
          return item.warehouse;
        }
      }
      return item.warehouse;
    },
    renderProductBatch(item, row) {
      if (row.isLast) {
        if (item.actualPrice || item.actualQty) {
          let str = "";
          if (this.selectModel) {
            str = `(${this.selectModel.actualPrice ? "￥" + this.selectModel.actualPrice + " " : ""}${
              this.selectModel.actualQty
            }${isNaN(Number(this.selectModel.actualQty)) ? "" : this.info.unit})`;
          }
          return item.productBatch + str;
        } else {
          return item.productBatch;
        }
      }
      return item.productBatch;
    },
    renderModelName(row) {
      return row.rename || this.buyModelOption[row.field];
    },
    renderPriceFn(price) {
      if (this.isMoreModel && this.selectModel) {
        return `${this.selectModel.showPrice || price}`;
      }
      return `${price}`;
    },
    renderQty(info) {
      if (this.isMoreModel && this.selectModel) {
        return this.selectModel.showQty || info.qty;
      }
      return info.qty;
    },
    selectWarehouse(row) {
      if (this.goodsOptions.warehouse === row.warehouse) {
        this.goodsOptions.warehouse = "";
      } else {
        this.goodsOptions.warehouse = row.warehouse;
      }
    },
    selectProductBatch(row) {
      if (this.goodsOptions.productBatch === row.productBatch) {
        this.goodsOptions.productBatch = "";
      } else {
        this.goodsOptions.productBatch = row.productBatch;
      }
    },
    disabledWarehouse(item) {
      if (this.goodsOptions.productBatch === "") {
        return false;
      }
      const list = this.goodsSpecificationList.filter(items => items.productBatch === this.goodsOptions.productBatch);
      if (list.length) {
        return !list.find(items => items.warehouse === item.warehouse);
      }
      return true;
    },
    disabledProductBatch(item) {
      if (this.goodsOptions.warehouse === "") {
        return false;
      }
      const list = this.goodsSpecificationList.filter(items => items.warehouse === this.goodsOptions.warehouse);
      if (list.length) {
        return !list.find(items => items.productBatch === item.productBatch);
      }
      return true;
    },
    showMoreGoodsInfoFn() {
      this.showMoreGoodsInfo = !this.showMoreGoodsInfo;
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F1":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.addShoppingCart();
          }
          break;
        case "F8":
          e.preventDefault();
          e.stopPropagation();
          this.createOrder();
          break;
      }
    },
    getOrderDetailsById() {
      this.loading = true;
      getOrderDetailsById({ id: this.$route.query.id })
        .then(res => {
          const info = res || {};
          if (info.stdVehModels) {
            this.tableData = info.stdVehModels.map(item => ({ name: item }));
          }
          this.info = info;
          this.info.minBuyQty = Number(this.info.minBuyQty || 0);
          if (Number(this.info.minBuyQty) && Number(this.info.uQty)) {
            this.info.minQty = window.tool.getLCM(Number(this.info.minBuyQty), Number(this.info.uQty));
          } else {
            this.info.minQty = 1;
          }
          this.orderQuantity = this.info.minQty;
          // 多规格
          this.optionList = info.optionList || [];
          this.goodsSpecificationList = info.goodsSpecificationList || [];

          const goodsBasicList = info.goodsBasic || [];
          const list = [];
          for (const item of goodsBasicList) {
            for (const key in info) {
              if (key == item.field) {
                const obj = {
                  ...item,
                  value: info[key]
                };
                list.push(obj);
              }
            }
          }
          this.goodsIntroduceList = list;
          this.isShowMoreBtnFn();
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "详情获取失败");
        });
    },
    getGoodsDetail() {
      const data = {
        goodsId: this.item.id,
        type: this.item.type,
        property: this.item.property,
        warehouse: this.item.warehouse,
        productBatch: this.item.productBatch
      };
      this.loading = true;
      getGoodsDetail(data)
        .then(res => {
          const info = res || {};
          if (info.stdVehModels) {
            this.tableData = info.stdVehModels.map(item => ({ name: item }));
          }
          this.info = info;
          this.info.minBuyQty = Number(this.info.minBuyQty || 1);
          if (Number(this.info.minBuyQty) && Number(this.info.uQty)) {
            this.info.minQty = window.tool.getLCM(Number(this.info.minBuyQty), Number(this.info.uQty));
          } else {
            this.info.minQty = 1;
          }
          this.orderQuantity = this.info.minQty;
          // 多规格
          this.optionList = info.optionList || [];
          this.goodsSpecificationList = info.goodsSpecificationList || [];

          const goodsBasicList = info.goodsBasic;
          const list = [];
          for (const item of goodsBasicList) {
            for (const key in info) {
              if (key == item.field) {
                const obj = {
                  ...item,
                  value: info[key] + ""
                };
                list.push(obj);
              }
            }
          }
          this.goodsIntroduceList = list;
          this.isShowMoreBtnFn();

          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "详情获取失败");
        });
    },
    isShowMoreBtnFn() {
      // 超过 rowCount:10 行显示展示更多
      if (this.goodsIntroduceList.length > 2 * this.rowCount) {
        this.showMoreBtn = true;
      } else {
        this.showMoreBtn = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    drawDown() {},
    claimCenter() {},
    addShoppingCart() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      // 多规格
      if (this.isMoreModel) {
        if (!this.selectModel) {
          this.$message.warning("请将商品规格选择完整后，再加入购物车。");
          return;
        }
      }

      if (Number(this.orderQuantity) <= 0) {
        this.$message.warning("订货数量必须大于0");
        return;
      }
      const data = [
        {
          goodsType: this.info.type, // 商品类型，0:普通，1:补货，2:促销组合，3:服务项目，4:套餐
          goodsId: this.info.id, // 商品ID
          code: this.info.code, // 商品编码
          name: this.info.name, // 商品名称
          property: this.info.property, // 商品性质
          warehouse: this.info.warehouse, // 仓库
          productBatch: this.info.productBatch, // 生产批号
          price: this.renderPriceFn(this.info.price), // 加购时价格
          qty: String(this.orderQuantity), // 数量
          remarks: this.info.remarks, // 备注
          checked: false, // 是否已选择
          areaDetailId: 0, // 商城区域详情ID，用来实时关联修理厂用
          goodsSpecification: this.selectModel // 商品规格
        }
      ];
      addShoppingCart({
        cover: false,
        data: data
      })
        .then(() => {
          this.$message.success("已成功加入购物车");
          this.$store.dispatch("user/getShoppingCartList");
          this.getGoodsDetail();
        })
        .catch(err => {
          this.$message.error(err.message || "加入购物车失败");
        });
    },
    createOrder() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      // 多规格
      if (this.isMoreModel) {
        if (!this.selectModel) {
          this.$message.warning("请将商品规格选择完整后，再进行下单。");
          return;
        }
      }

      const data = window.tool.deepClone(this.info);
      data.price = this.renderPriceFn(this.info.price);
      data.qty = String(this.orderQuantity);
      data.goodsSpecification = this.selectModel;

      this.$refs.placeOrderType.show({
        type: "productsDetails",
        data: data
      });
    },
    setPropertyTagMore() {
      setTimeout(() => {
        const propertyTagItemRef = this.$refs.propertyTagItemRef;
        if (
          propertyTagItemRef &&
          this.$refs.propertyTagBoxRef[0].scrollHeight > propertyTagItemRef[0].$el.offsetHeight + 20
        ) {
          this.isShowMorePropertyBtn = true;
        }
      }, 300);
    },
    showMorePropertyFn() {
      this.showMoreProperty = !this.showMoreProperty;
    },
    setStockTagMore() {
      setTimeout(() => {
        const stockTagItemRef = this.$refs.stockTagItemRef;
        if (stockTagItemRef && this.$refs.stockTagBoxRef[0].scrollHeight > stockTagItemRef[0].$el.offsetHeight + 20) {
          this.isShowMoreStockBtn = true;
        }
      }, 300);
    },
    setProductBatchTagMore() {
      setTimeout(() => {
        const productBatchTagItemRef = this.$refs.productBatchTagItemRef;
        if (
          productBatchTagItemRef &&
          this.$refs.productBatchTagBoxRef[0].scrollHeight > productBatchTagItemRef[0].$el.offsetHeight + 20
        ) {
          this.isShowMoreProductBatchBtn = true;
        }
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.client-products-details {
  overflow: auto !important;
  .content-title {
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #eeeeee;
  }
  .content-details {
    padding: 20px 10px;
    box-sizing: border-box;
    background-color: white;
  }
  .content-details-left {
    width: 400px;
    margin-right: 20px;
  }
  .content-details-right {
    overflow: hidden;
    position: relative;
    flex: 1;
    .max-out,
    .discount {
      color: white;
      font-size: 14px;
      padding: 2px 8px;
      background-color: #ff8400;
    }
    .discount {
      background-color: #e84615;
    }
    /deep/ .el-input__inner {
      font-size: 16px;
      font-weight: bolder;
    }
    .btn-box {
      right: 0;
      bottom: 0;
      position: absolute;
    }
    .tag-btn {
      height: 35px;
      font-size: 16px;
      padding: 7px 15px;
      margin: 0 15px 10px 0;
      float: left;
    }
  }
  .content-info {
    padding: 0 10px;
    margin-bottom: 50px;
    border-top: 10px solid #eeeeee;
    /deep/ .el-descriptions-row {
      th {
        width: 100px;
        min-width: 100px;
        color: #000000;
        background-color: #eeeeee;
      }
      td {
        width: 1000px;
      }
    }
    /deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
      border: 1px solid rgba(153, 153, 153, 0.5);
    }
    .show-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      border-top: 0;
    }
    .rich-text-content {
      img {
        max-width: 100%;
      }
    }
  }
  .max-h-130 {
    max-height: 130px;
  }
  .el-tag {
    font-size: 14px;
  }
}
</style>
